<template>
  <div>
    <SiteHeader></SiteHeader>

    <div class="topbar text-center">
      <strong class="fs-1">Order Detail: No#{{ detail.orderno }}</strong>
      <div class="menu">
        <div class="alink" @click="toCart">My Cart</div>
        <div class="alink current" @click="toOrders">Orders</div>
        <div class="alink">Account</div>
        <div class="alink" @click="toMessages">Messages</div>
        <div class="alink" @click="toAbout">About</div>
      </div>
    </div>

    <div class="container mt-5 mb-5">
      <div class="order_confirm ">
        <div class="tophead psrel">
          <h3 class="text-center">Sales Order</h3>
          <h4 class="text-center">
            Hong Kong MACNN International Group Limited.
          </h4>

          <hr />

          <section class="psabs">
            <span>Order No.<br />{{ detail.orderno }}</span>
            <span>S/O Date<br />{{ detail.date }}</span>
          </section>
        </div>

        <div class="address mb-3 ">
          <dl>
            <dt>Billing Address</dt>
            <dd >
              <p v-if="detail.business.company">Company: {{ detail.business.company }}</p>
              <p v-if="detail.business.name">Contact:  {{ detail.business.name }}</p>
              <p v-if="detail.business.tel">Tel： {{ detail.business.tel }}</p>
              <p v-if="detail.business.tax">Tax: {{ detail.business.tax }} </p>
            </dd>
          </dl>

          <dl >
            <dt>Shipping Address</dt>
            <dd >
              <p v-if="detail.shipping_address.companyName">{{ detail.shipping_address.companyName }}</p>
              <p v-if="detail.shipping_address.fName">Contact： {{ detail.shipping_address.fName }}{{ detail.shipping_address.lName }}</p>
              <p v-if="detail.shipping_address.postcode">Postcode： {{ detail.shipping_address.postcode }}</p>
              <p v-if="detail.shipping_address.aDetail">
                Delivery Address：
                {{ detail.shipping_address.aDetail }}
                {{ detail.shipping_address.aCity }},
                {{ detail.shipping_address.aProvince }},
                {{ detail.shipping_address.aCountry }} 
              </p>
            </dd>
          </dl>
        </div>

        <table class="table table-bordered otitle">
          <tbody>
            <tr>
              <td width="20%">
                ETD: {{ detail.shipat }}
              </td>
              <td>Customer: {{ detail.customer_id }}</td>
              <td width="30%"> {{ detail.shipway }}</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-bordered olist">
          <thead>
            <tr class="text-center">
              <th
                scope="col"
                width="5%"
                class="pe-0 ps-0"
              >
                ID
              </th>
              <th scope="col" width="15%">Item Code</th>
              <th scope="col" width="35%" class="text-left">Description</th>
              <th scope="col" width="10%">Case Pack</th>
              <th
                scope="col"
                width="10%"
                class="ps-0 text-right"
              >
                Unit Price
              </th>
              <th scope="col" width="12%">QTY</th>
              <th
                scope="col"
                width="13%"
                class="ps-0 text-right"
              >
                Extended Price
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style="text-align: center" v-for="(v, i) in detail.order_items">
              <th style="font-weight: normal;">{{ v.id }}</th>
              <td>{{ v.code }} - {{ v.dash }}</td>
              <td class="text-left">{{ v.title }}</td>
              <td class="each_goods_qty">{{ v.casepack }}</td>
              <td class="text-right">
                <em>$ {{ v.price }}</em>
              </td>
              <td class="each_nums">{{ v.nums }}</td>
              <td class="each_price text-right">${{ v.price }} USD</td>
            </tr>
          </tbody>
        </table>

        <table class="table table-bordered ototal">
          <tbody>
            <tr>
              <td width="55%" colspan="3" style="vertical-align: top">
                <p><b>Comments：</b></p>
                <p>{{ detail.comments }}</p>
              </td>
              <td width="20%" colspan="2" style="vertical-align: top">
                <p><b>Packaging Information</b></p>
                <p>Quantity：{{ detail.package_info.quantities }} pcs</p>
                <p>
                  Original Box：<i class="total_boxes">{{
                    detail.package_info.originbox
                  }}</i>
                  Cartons
                </p>
                <p>
                  Total Weight：{{ detail.package_info.boxweight }} KG
                </p>
              </td>
              <td width="12%" class="text-right" style="vertical-align: top">
                <p><b>Billing</b></p>
                <p>Sub Amount:</p>
                <p>Shipping Fee:</p>
              </td>
              <td width="13%" class="text-right" style="vertical-align: top">
                <p>&nbsp;</p>
                <p>
                  $<i>{{ detail.subamount }}</i>
                </p>
                <p class="text-danger">{{ detail.shipping }}</p>
              </td>
            </tr>
            <tr>
              <td colspan="3"></td>
              <td colspan="2" width="20%">
                <b>Total Order Payable：</b>
              </td>
              <td colspan="2" width="25%" class="text-right">
                  <b>$ {{ detail.subamount }} USD</b>
              </td>
            </tr>
          </tbody>
        </table>

        <h6>Please double check your current order and confirm</h6>

        <p class="fs-7">
          Note: Prices are subject to change due to changes in manufacturer
          sales policies or rising shipping costs. Order has been effective, if
          you need to cancel the order will be charged 10% of the default fee in
          the next time. After all goods are sent out, please check and accept
          them in person, and sign for them as the order has been successfully
          completed.
        </p>

        <div class="clearfix"></div>
      </div>

      <div class="clearfix"></div>
    </div>

    <Foot></Foot>
  </div>
</template>

<script>
import SiteHeader from "@/components/website/SiteHeader.vue";

import Foot from "@/components/website/Foot.vue";
import { orderDetailConfirm } from "@/api/index.js";

export default {
  name: "PcWebsiteIndex",

  components: {
    SiteHeader,
    Foot,
  },

  // 0 待确认 1.已创建 2.已确认 3.已付款 4.已发货 5.已完成

  data() {
    return {
      detail: {},
      isLoading: false,
    };
  },

  computed: {
    token: {
      get() {
        return this.$store.state.user.token;
      },
    },
    userinfo: {
      get() {
        return this.$store.state.user.userinfo;
      },
    },
  },

  watch: {},
  mounted() {
    //获取价格并计算单价和更新价格
    this.getDetail();
  },
  methods: {
    getDetail() {
      orderDetailConfirm({ orderno: this.$route.params.orderno }).then(
        (res) => {
          this.detail = res.data;
        }
      );
    },
    toCart() {
      this.$router.push("/cart");
    },
    toOrders() {
      this.$router.push("/order/list");
    },
    toAccount() {
      this.$router.push("/account");
    },
    toMessages() {
      this.$router.push("/message");
    },
    toAbout() {
      this.$router.push("/about");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.order_confirm {
  background-color: var(--el-bg-color);
  border: 1px solid var(--el-border-color);
  .bg2 {
    background-color: var(--el-bg-color-page);
  }

  .table {
    background-color: var(--el-bg-color);
    td,th {
      background-color: var(--el-bg-color);
      color: var(--el-text-color-primary);
    }
    tfoot {
      border-width: 0px;
      th {
        border: 0px none;
      }
    }
  }
}
</style>
